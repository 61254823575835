export const getColumns = [
  {
    slot: 'packagingCode',
    label: '包装代码'
  },
  {
    prop: 'brandName',
    label: '品牌',
    width: 160
  },
  {
    prop: 'typeDictI18',
    label: '包装类型'
  },
  {
    slot: 'remark',
    label: '材质/款式说明'
  },
  {
    slot: 'mainInfo',
    label: '主要包装辅材'
  },
  {
    prop: 'modifyTime',
    label: '操作时间',
    width: '200'
  },
  {
    prop: 'version',
    label: '最新版本号',
    width: '120'
  },
  {
    slot: 'operate',
    label: '操作'
  },
  {
    slot: 'newest',
    label: '最新版本变更点'
  },

  {
    slot: 'version',
    label: '版本记录'
  }
]

export const templateTableColumns = [
  { label: '修改者', prop: 'modifyByName' },
  { label: '修改时间', prop: 'modifyTime' },
  { label: '版本号', prop: 'version' },
  { label: '附件', slot: 'attachmentIdList' },
  { label: '版本变更点', slot: 'versionFileUrl' }
]

