import { render, staticRenderFns } from "./versionList.vue?vue&type=template&id=80d0d88a&scoped=true"
import script from "./versionList.vue?vue&type=script&lang=js"
export * from "./versionList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80d0d88a",
  null
  
)

export default component.exports