<template>
  <div class="app-container">
    <el-form
      ref="ruleForm"
      :model="form"
      label-width="134px"
      style="display: flex; flex-wrap: wrap; margin-top: 15px"
    >
      <div class="el-lt" style="width: 97%">
        <el-row :span="24" class="row-input">
          <el-col :span="8">
            <el-form-item label="包装代码" :class="$i18n.locale">
              <el-select
                v-model="form.ids"
                class="style_w100"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="(item,index) in packagingCodeOptions"
                  :key="index"
                  :label="item.packagingCode"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="品牌" :class="$i18n.locale">
              <el-select
                v-model="form.brandIds"
                class="style_w100"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in brandOptions"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作时间段" :class="$i18n.locale">
              <el-date-picker
                v-model="form.operateTime"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="el-rt header_btn">
        <div>
          <el-button
            v-permission="'add'"
            type="primary"
            class="ml-4"
            :loading="loading"
            @click="handleAdd"
          >
            新增
          </el-button>

        </div>

        <div class="row-center">
          <el-button
            type="primary"
            class="ml-4"
            :loading="loading"
            @click="queryClick(1)"
          >{{ $t("page.search") }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button
            v-permission="'export'"
            type="primary"
            class="ml-4"
            :loading="exportLoading"
            @click="_exportDetail"
          >导出</el-button>
        </div>
      </div>
    </el-form>

    <Table
      ref="packagingTable"
      :table-data="tableDatas"
      max-height="600px"
      :columns="getColumns"
      :page-obj="pager"
      :loading="tableLoading"
      border
    >
      <el-table-column slot="packagingCode" label="包装代码" align="center">
        <template slot-scope="{row}">
          <router-link
            :to="{ path: '/purchasbusiness/packaging-code/added', query: { 'id': row.id, type: 'view' }}"
            style="color:#1890ff;"
          >{{ row.packagingCode }}</router-link>
        </template>
      </el-table-column>
      <el-table-column slot="newest" label="最新版本变更点" width="120" align="center">
        <template slot-scope="{row}">
          <el-button
            :loading="loading"
            type="text"
            size="small"
            @click="handleVersion(row)"
          >
            点击查看
          </el-button>
        </template>
      </el-table-column>
      <el-table-column slot="remark" label="材质/款式说明" width="120" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">{{ row.remark }}</template>
      </el-table-column>
      <el-table-column slot="mainInfo" label="主要包装辅材" width="120" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">{{ row.mainInfo }}</template>
      </el-table-column>
      <el-table-column slot="operate" label="操作" width="120" align="center">
        <template slot-scope="{row}">
          <router-link
            :to="{ path: '/purchasbusiness/packaging-code/added', query: { 'id': row.id, type: 'edit','version':row.version }}"
            style="color:#1890ff;"
          >
            <el-button
              v-permission="'edit'"
              :loading="loading"
              type="text"
              size="small"
            >修改</el-button>
          </router-link>
          <el-button
            v-permission="'download'"
            class="ml-3"
            :loading="loading"
            type="text"
            size="small"
            @click="handleDownloads(row)"
          >
            下载附件
          </el-button>
        </template>
      </el-table-column>
      <el-table-column slot="version" label="版本记录" width="120" align="center">
        <template slot-scope="{row}">
          <el-button
            v-permission="'version'"
            :loading="loading"
            type="text"
            size="small"
            @click="showVersion(row)"
          >
            版本记录
          </el-button>
        </template>
      </el-table-column>
    </Table>
    <div class="block">
      <Paging :pager="pager" end :page-sizes="pageSizes" @pagination="_purchasePage" />
    </div>
    <VersionList v-model="versionVisible" :version-datas="versionDatas" :version-id="versionId" />
  </div>
</template>
<script>
import { queryBrandList } from '@/api/listSelection'
import { packagingCode, getPackagingCode, versionRecord, packagingCodeDownLoad, PackagingExport } from '@/api/package'
import Table from '@/components/Table'
import Paging from '@/components/Pagination'
import { downloads } from '@/utils'
import { getColumns } from './components/columns'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { omit } from 'lodash'
import VersionList from './components/versionList.vue'

export default {
  components: {
    Paging,
    Table,
    VersionList
  },
  mixins: [commodityInfoDict],
  data() {
    return {
      getColumns,
      pageSizes: [10, 20, 30, 40, 50],
      brandOptions: [],
      packagingCodeOptions: [],
      form: {
        ids: [],
        brandIds: [],
        operateTime: []
      },
      tableLoading: false,
      loading: false,
      exportLoading: false,
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      tableDatas: [],
      importVisible: false,
      standardVisible: false,
      versionVisible: false,
      versionDatas: [],
      versionId: 0
    }
  },
  computed: {
    queryParams() {
      const { operateTime, ids } = this.form
      const [startTime, endTime] = operateTime || []
      return Object.assign({}, omit(this.form, ['operateTime']),
        {
          startTime, endTime,
          ids: ids && ids !== '' ? [ids] : []
        })
    }
  },
  mounted() {
    this._brandList()
    this.queryClick()
    this._getPackagingCode()
  },
  methods: {
    handleAdd() {
      this.$router.push({
        path: 'added',
        append: 'true',
        query: { type: 'add' }
      })
    },
    toModify(row) {
      this.$router.push({
        path: 'added',
        append: 'true',
        query: { 'id': row.id, type: 'edit' }
      })
    },

    async showVersion(row) {
      try {
        this.loading = true
        const { datas } = await versionRecord(row.packagingCode)
        this.versionDatas = datas
        this.versionId = this.packagingCodeOptions.find(item => item.packagingCode === row.packagingCode).id
        this.versionVisible = true
      } finally {
        this.loading = false
      }
    },

    // brand
    async _brandList() {
      const { datas } = await queryBrandList()
      this.brandOptions = datas
    },
    async _getPackagingCode() {
      const { datas } = await getPackagingCode()
      this.packagingCodeOptions = datas
    },

    async queryClick(flag) {
      try {
        this.loading = true
        this.tableLoading = true
        flag && flag === 1 ? (this.pager.current = 1) : ''
        const {
          datas: { pager, records }
        } = await packagingCode(this.queryParams, this.pager)
        this.pager = pager
        this.tableDatas = records

        this.loading = false
        this.tableLoading = false
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
        this.tableLoading = false
      }
    },
    async _exportDetail() {
      try {
        this.exportLoading = true
        await PackagingExport(this.queryParams)
        this.$notify({
          message: '操作成功，请前往文件导出界面下载',
          type: 'success'
        })
        this.exportLoading = false
      } catch (err) {
        console.log(err)
      } finally {
        this.exportLoading = false
      }
    },
    resetClick() {
      // 重置表单
      this.form = {}
      this.queryClick(1)
    },

    async _purchasePage(pagers) {
      this.pager = pagers
      this.queryClick()
    },
    async handleDownloads(row) {
      try {
        this.loading = true
        if (!row.versionFileUrl) return this.$message.warning('【版本说明】正在生成，请稍后查看')
        const packagingId = this.packagingCodeOptions.find(item => item.packagingCode === row.packagingCode).id
        const { datas } = await packagingCodeDownLoad(JSON.parse(row.attachmentIdList), packagingId)
        downloads(datas)
      } finally {
        this.loading = false
      }
    },
    handleVersion(row) {
      if (!row.versionFileUrl) return this.$message.warning('【版本说明】正在生成，请稍后查看')
      window.open(row.versionFileUrl, '_blank')
    }
  }
}
</script>
<style lang="scss" scope>

.style_w100 {
  width: 100%;
}
.header_btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.header_replenishCycle {
  margin-bottom: 0
}

</style>
