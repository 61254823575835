<template>
  <div>
    <el-dialog
      title="版本记录"
      :visible.sync="visible"
      width="60%"
      :close-on-click-modal="false"
    >
      <Table
        ref="versionTable"
        :table-data="tableDatas"
        max-height="600px"
        :columns="templateTableColumns"
        border
      >
        <el-table-column slot="versionFileUrl" label="版本变更点" width="120" align="center">
          <template slot-scope="{row}">
            <el-button
              :loading="loading"
              type="text"
              size="small"
              @click="handleVersion(row)"
            >
              点击查看
            </el-button>
          </template>
        </el-table-column>
        <el-table-column slot="attachmentIdList" label="附件" width="120" align="center">
          <template slot-scope="{row}">
            <el-button
              :loading="loading"
              type="text"
              size="small"
              @click="handleDownloads(row)"
            >
              下载附件
            </el-button>
          </template>
        </el-table-column>
      </Table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('input',false)">关闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { templateTableColumns } from './columns'
import { packagingCodeDownLoad } from '@/api/package'
import Table from '@/components/Table'
import { downloads } from '@/utils'
export default {
  components: { Table },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    versionDatas: {
      type: Array,
      default: () => []
    },
    versionId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      templateTableColumns,
      loading: false,
      tableDatas: []
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'versionDatas'(val) {
      if (val) {
        this.tableDatas = this.versionDatas
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    handleVersion(row) {
      window.open(row.versionFileUrl, '_blank')
    },
    async handleDownloads(row) {
      console.log(JSON.parse(row.attachmentIdList))
      const { datas } = await packagingCodeDownLoad(JSON.parse(row.attachmentIdList), this.versionId)
      downloads(datas)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
